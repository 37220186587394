import styles from "./styles/login.module.css";

import { useState } from "react";

import Button from "../../components/UI/button/Button";

import request from "../../api/request";

import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/reducers/userReducer";

import { useNavigate } from "react-router-dom";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [disable, setDisable] = useState(false);
    const [error, setError] = useState("");
    const handleSubmit = async (e) => {
        try {
            setDisable(true);
            e.preventDefault();
            const response = await request({
                endPoint: "login",
                method: "post",
                data: {
                    email,
                    password,
                },
            });
            setError(false);
            localStorage.setItem("access", response.data.access);
            localStorage.setItem("refresh", response.data.refresh);
            dispatch(setAuth("load"));
            navigate("/");
        } catch (error) {
            setError(error.response.data.detail);
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
            dispatch(setAuth(false));
        } finally {
            setTimeout(() => setDisable(false), 2000);
        }
    };
    return (
        <div className={styles.login__container}>
            <h1 className={styles.login__title}>Photobank</h1>
            <form onSubmit={handleSubmit} className={styles.login__form}>
                <p className={styles.form__title}>Вход в систему</p>
                <div className={styles.form__body}>
                    <input
                        type={"email"}
                        value={email}
                        className={styles.form__input}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={"Адрес электронной почты"}
                    />
                    <input
                        type={"password"}
                        value={password}
                        className={styles.form__input}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={"Пароль"}
                    />
                </div>
                {error && <p className={styles.form__error}>{error}</p>}
                <Button
                    disabled={disable}
                    type={"submit"}
                    style={{ marginLeft: "auto", marginTop: "15px" }}
                >
                    Войти
                </Button>
            </form>
        </div>
    );
};

export default Login;

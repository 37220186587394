const defaultState = {
    auth: "load",
    data: false,
};

const SET_AUTH = "SET_AUTH";
const SET_USER_DATA = "SET_USER_DATA";

export const userReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_AUTH:
            return { ...state, auth: action.payload };
        case SET_USER_DATA:
            return { ...state, data: action.payload };
        default:
            return state;
    }
};

export const setAuth = (payload) => ({
    type: SET_AUTH,
    payload,
});

export const setUserData = (payload) => ({
    type: SET_USER_DATA,
    payload,
});

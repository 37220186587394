import styles from "./styles/header.module.css";

import Button from "../button/Button";
import BurgerMenu from "../burgerMenu/BurgerMenu";

import { useSelector, useDispatch } from "react-redux";
import { setAuth, setUserData } from "../../../redux/reducers/userReducer";

import { useNavigate } from "react-router-dom";

import { AiFillFileAdd } from "react-icons/ai";
import { FaHamburger } from "react-icons/fa";

import { useState } from "react";

const Header = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState();
    const user = useSelector((store) => store.user.data);
    const dispatch = useDispatch();
    const logoutUser = () => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        dispatch(setAuth(false));
        dispatch(setUserData(false));
    };
    return (
        <>
            <header className={styles.header}>
                <p
                    className={styles.header__logo}
                    onClick={() => navigate("/")}
                >
                    Photobank
                </p>
                <div className={styles.header__body}>
                    <Button
                        primary
                        style={{ marginRight: "auto", whiteSpace: "nowrap" }}
                        onClick={() => navigate("/upload")}
                    >
                        <AiFillFileAdd size={20} />
                        Добавить файл
                    </Button>
                    <p className={styles.header__user_name}>{user.fio}</p>
                    <Button onClick={logoutUser}>Выйти</Button>
                </div>
                <FaHamburger
                    size={24}
                    color="white"
                    className={styles.header__burger}
                    onClick={() => setOpen(!open)}
                />
            </header>
            <BurgerMenu open={open} setOpen={setOpen} />
        </>
    );
};

export default Header;

import styles from "./styles/modalUpload.module.css";

import Space from "../../assets/videos/space-bg.gif";

import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { AiFillFileImage } from "react-icons/ai";
import { GrClose } from "react-icons/gr";

const ModalUpload = ({ open, setOpen, files, setFiles }) => {
    const navigate = useNavigate();
    const [drag, setDrag] = useState(false);
    const [error, setError] = useState(false);
    const dragStartHandler = (e) => {
        e.preventDefault();
        setDrag(true);
    };
    const dragLeaveHandler = (e) => {
        e.preventDefault();
        setDrag(false);
    };
    const onDropHandler = (e) => {
        e.preventDefault();
        let newFiles = [...files, ...e.dataTransfer.files];
        let error = false;
        for (let file of newFiles) {
            if (
                file.type !== "image/png" &&
                file.type !== "image/jpeg" &&
                file.type !== "video/mp4"
            ) {
                error = "Неподдерживаемый формат";
                break;
            }
        }
        if (newFiles.length > 10) {
            error = "Максимальное количество файлов 10 шт.";
        }
        if (error) {
            setError(error);
        } else {
            setError(false);
            setFiles(newFiles);
            setOpen(false);
        }
        setDrag(false);
    };
    const upLoadImage = (e) => {
        let newFiles = [...files, ...e.target.files];
        let error = false;
        if (newFiles.length > 10) {
            error = "Максимальное количество файлов 10 шт.";
        }
        if (error) {
            setError(error);
        } else {
            setError(error);
            setFiles(newFiles);
            setOpen(false);
        }
    };
    return (
        <div
            className={styles.modal}
            style={{ display: open ? "flex" : "none" }}
        >
            <div className={styles.modal__body}>
                <div className={styles.modal__drop}>
                    <div
                        className={styles.drop__area}
                        onDragStart={dragStartHandler}
                        onDragLeave={dragLeaveHandler}
                        onDragOver={dragStartHandler}
                        onDrop={onDropHandler}
                    >
                        {drag ? "Отпустите файл(ы)" : "Перетащите файл(ы)"}
                    </div>
                    {drag && (
                        <>
                            <img
                                className={styles.modal__drop_active}
                                src={Space}
                                alt="space"
                            />
                            <div
                                className={styles.modal__drop_active_opacity}
                            />
                        </>
                    )}
                </div>
                <div className={styles.modal__select}>
                    <input
                        type="file"
                        id="file"
                        accept=".jpg,.png,.mp4"
                        multiple="multiple"
                        onChange={upLoadImage}
                    />
                    <label for="file" className={styles.select__file}>
                        <AiFillFileImage /> Выберите файл(ы)
                    </label>
                    <p className={styles.select__text}>
                        Форматы: jpg, png, mp4 размер без ограничений
                    </p>
                    {error && <p className={styles.modal__error}>{error}</p>}
                </div>
                <button
                    className={styles.modal__close}
                    onClick={() =>
                        files.length > 0 ? setOpen(false) : navigate("/")
                    }
                >
                    <GrClose size={18} />
                </button>
            </div>
        </div>
    );
};

export default ModalUpload;

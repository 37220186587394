import styles from "./styles/button.module.css";

const Button = (props) => {
    return (
        <button
            className={`${styles.button} ${
                props.primary ? styles.button_primary : ""
            }`}
            {...props}
        >
            {props.children}
        </button>
    );
};

export default Button;

import styles from "./styles/upLoad.module.css";

import { useEffect } from "react";
import { useState } from "react";

import ModalUpload from "../../components/modalUpload/ModalUpload";
import CardUpload from "../../components/cardUpload/CardUpload";

import Button from "../../components/UI/button/Button";

const UpLoad = () => {
    const [openModal, setOpenModal] = useState(false);
    const [files, setFiles] = useState([]);
    const [genDescription, setGenDescription] = useState("");
    const [load, setLoad] = useState(false);
    const [errors, setErrors] = useState([]);
    const removeFile = (file) => {
        setFiles((prev) => prev.filter((elem) => elem !== file));
    };
    const addError = (filename) => {
        setErrors((prev) => {
            const newErrors = [...prev];
            newErrors.push(filename);
            return newErrors;
        });
    };
    const loading = () => {
        setLoad(true);
    };
    useEffect(() => {
        if (files.length < 1) {
            setOpenModal(true);
        }
        if (files.length < 2) {
            setGenDescription("");
        }
    }, [files]);
    useEffect(() => {
        if (load && errors.length === files.length) {
            setLoad(false);
            setErrors([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files, errors]);
    return (
        <div>
            <ModalUpload
                open={openModal}
                setOpen={setOpenModal}
                files={files}
                setFiles={setFiles}
            />
            {files.length > 0 && (
                <div
                    className={styles.upload__container}
                    style={{
                        gridTemplateColumns:
                            files.length > 1 ? "50% 48%" : "1fr",
                    }}
                >
                    <div className={styles.upload__cards}>
                        {files.map((file) => (
                            <CardUpload
                                key={file.name}
                                file={file}
                                removeFile={() => removeFile(file)}
                                addError={() => addError(file.name)}
                                load={load}
                                genDescription={genDescription}
                            />
                        ))}
                        <div className={styles.upload__buttons}>
                            <Button
                                disabled={load}
                                primary
                                style={{ fontSize: "24px" }}
                                onClick={() => setOpenModal(true)}
                            >
                                Добавить файл
                            </Button>
                            <Button
                                disabled={load}
                                style={{ fontSize: "24px" }}
                                onClick={loading}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                    {files.length > 1 && (
                        <div className={styles.upload__gen_description}>
                            <p>Общее описание</p>
                            <textarea
                                value={genDescription}
                                placeholder="Вы можете добавить общее описание для всех загружаемых файлов"
                                onChange={(e) =>
                                    setGenDescription(e.target.value)
                                }
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default UpLoad;

import styles from "./styles/main.module.css";

import Search from "../../components/search/Search";
import CardMain from "../../components/cardMain/CardMain";
import ModalMain from "../../components/modalMain/ModalMain";
import Button from "../../components/UI/button/Button";

import { AiOutlineInfoCircle, AiOutlineSearch } from "react-icons/ai";

import request from "../../api/request";

import { useState, useEffect } from "react";

const Main = () => {
    const [search, setSearch] = useState("");
    const [searchVideo, setSearchVideo] = useState(false);
    const [cards, setCards] = useState([]);
    const [active, setActive] = useState(false);
    const [count, setCount] = useState(false);
    const [limit, setLimit] = useState(20);
    const [first, setFirst] = useState(true);
    const getCards = async () => {
        try {
            const response = await request({
                endPoint: `search${
                    searchVideo ? "-video" : ""
                }/${search}/?limit=${limit}&offset=0`,
                method: "get",
            });
            return response.data;
        } catch {
            return [];
        }
    };

    useEffect(() => {
        if (search.length > 1) {
            if (first) {
                setFirst(false);
            }
            getCards().then((data) => {
                setCards(data.results);
                setCount(data.count);
            });
        } else {
            setCount(false);
            setCards([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, searchVideo, limit]);
    useEffect(() => {
        setLimit(20);
    }, [search]);
    useEffect(() => {
        if (active) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [active]);

    return (
        <div
            className={`${styles.main__container}  ${
                first ? styles.main__container_first : ""
            }`}
        >
            <Search
                search={search}
                border={first}
                setSearch={(value) => setSearch(value)}
                searchVideo={searchVideo}
                setSearchVideo={(value) => setSearchVideo(value)}
            />
            {search.length < 1 && (
                <p className={styles.main__info}>
                    <AiOutlineInfoCircle size={22} /> Для получения файлов
                    введите запрос в строку поиска и нажмите Enter или{" "}
                    <AiOutlineSearch size={22} />
                </p>
            )}
            {search.length > 1 && cards.length < 1 && (
                <p className={styles.main__info}>
                    <AiOutlineInfoCircle size={22} /> По вашему запросу ничего
                    не найдено
                </p>
            )}
            <div className={styles.main__body}>
                {cards?.map((card) => (
                    <CardMain
                        key={card.id}
                        card={card}
                        setActive={() => setActive(card)}
                    />
                ))}
            </div>
            {limit < count && count && (
                <Button
                    primary
                    style={{ margin: "0 auto", fontSize: "20px" }}
                    onClick={() => setLimit(limit + 10)}
                >
                    Загрузить еще
                </Button>
            )}

            <ModalMain active={active} setActive={setActive} />
        </div>
    );
};

export default Main;

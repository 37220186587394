import styles from "./styles/search.module.css";
import { AiOutlineSearch } from "react-icons/ai";

import { useState } from "react";

import CheckBox from "../UI/checkBox/CheckBox";

const Search = ({ border, setSearch, searchVideo, setSearchVideo }) => {
    const [value, setValue] = useState("");
    const [flicker, setFlicker] = useState(false);
    const handleValue = (e) => {
        setValue(e.target.value);
        if (flicker === false) {
            setFlicker(true);
        }
    };
    const handleSearch = (e) => {
        e.preventDefault();
        setSearch(value);
        setFlicker(false);
    };
    return (
        <form onSubmit={handleSearch} className={styles.search__input}>
            <input
                style={{ borderRadius: border ? "30px" : "0" }}
                value={value}
                onChange={handleValue}
                placeholder="Поиск..."
            />
            <AiOutlineSearch
                className={`${styles.search__icon} ${
                    flicker > 0 && styles.search__icon_animate
                }`}
                size={32}
                onClick={() => {
                    setSearch(value);
                    setFlicker(false);
                }}
            />
            <div className={styles.video_checkbox}>
                Видео
                <CheckBox
                    checked={searchVideo}
                    onChange={(e) => setSearchVideo(e.target.checked)}
                />
            </div>
        </form>
    );
};

export default Search;

import styles from "./styles/burgerMenu.module.css";

import Button from "../button/Button";

import { useSelector, useDispatch } from "react-redux";
import { setAuth, setUserData } from "../../../redux/reducers/userReducer";

import { useNavigate } from "react-router-dom";

import { AiFillFileAdd } from "react-icons/ai";

const BurgerMenu = ({ open, setOpen }) => {
    const navigate = useNavigate();
    const user = useSelector((store) => store.user.data);
    const dispatch = useDispatch();
    const logoutUser = () => {
        setOpen(false);
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        dispatch(setAuth(false));
        setUserData(setUserData(false));
    };
    return (
        <div
            className={`${styles.burgerMenu} ${open && styles.burgerMenu_open}`}
        >
            <p className={styles.burgerMenu__user_name}>{user.fio}</p>
            <Button
                primary
                style={{ width: "100%" }}
                onClick={() => {
                    navigate("/upload");
                    setOpen(false);
                }}
            >
                <AiFillFileAdd size={18} />
                Добавить файл
            </Button>
            <Button style={{ width: "100%" }} onClick={logoutUser}>
                Выйти
            </Button>
        </div>
    );
};

export default BurgerMenu;

import styles from "./styles/cardUpload.module.css";

import { useState, useEffect } from "react";

import request from "../../api/request";

import { GrClose } from "react-icons/gr";
import { IoIosVideocam } from "react-icons/io";

import showToast from "../../helpers/showToast";

import VideoThumbnail from "react-video-thumbnail";

const CardUpload = ({ file, removeFile, addError, load, genDescription }) => {
    const [name, setName] = useState(file.name);
    const [author, setAuthor] = useState("Donday.ru");
    const [description, setDescription] = useState("");
    const saveImage = async () => {
        try {
            const data = new FormData();
            if (file.type === "video/mp4") {
                data.append("video", file);
            } else {
                data.append("image", file);
                data.append("preview", file);
            }
            data.append("name", name);
            data.append("author", author);
            data.append("description", description);
            data.append("general_description", genDescription);
            if (file.type === "video/mp4") {
                await request({
                    endPoint: "add-video",
                    method: "post-fd",
                    data,
                });
            } else {
                await request({
                    endPoint: "add-image",
                    method: "post-fd",
                    data,
                });
            }
            removeFile();
            showToast(`${name} успешно загружен!`, "success");
        } catch (error) {
            addError();
            showToast(
                `${name} ${
                    typeof error.response.data.detail === "object"
                        ? error.response.data.detail.join(", ")
                        : error.response.data?.detail
                        ? `${error.response.data.detail}`
                        : "что-то пошло не так!"
                }`,
                "error"
            );
        }
    };
    useEffect(() => {
        if (load) {
            saveImage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load]);
    return (
        <div className={styles.card}>
            <div className={styles.card__image}>
                {file.type === "video/mp4" ? (
                    <VideoThumbnail
                        videoUrl={URL.createObjectURL(file)}
                        snapshotAtTime={0}
                        alt="preview video"
                    />
                ) : (
                    <img src={URL.createObjectURL(file)} alt="upload" />
                )}
            </div>
            <div className={styles.card__fields}>
                <div className={styles.card__input}>
                    <p>Название:</p>
                    <input
                        value={name}
                        onChange={(e) => setName(`${e.target.value}`)}
                        placeholder="Введите название..."
                    />
                </div>
                <div className={styles.card__input}>
                    <p>Автор:</p>
                    <input
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        placeholder="Введите автора..."
                    />
                </div>
                <div className={`${styles.card__input}`}>
                    <p>Описание:</p>
                    <input
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Введите описание..."
                    />
                </div>
            </div>
            {file.type === "video/mp4" && (
                <div className="video_icon">
                    <IoIosVideocam size={48} />
                </div>
            )}

            <button
                className={styles.card__delete}
                onClick={() => {
                    setTimeout(removeFile, 300);
                }}
            >
                <GrClose size={18} />
            </button>
        </div>
    );
};

export default CardUpload;

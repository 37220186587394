import AuthRouter from "./routes/AuthRouter";
import NotAuthRouter from "./routes/NotAuthRouter";

import "react-loading-skeleton/dist/skeleton.css";

import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setUserData, setAuth } from "./redux/reducers/userReducer";

import { useNavigate, useLocation } from "react-router-dom";
import request from "./api/request";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useSelector((store) => store.user.auth);
    const dispatch = useDispatch();
    const getUser = async () => {
        try {
            const response = await request({
                endPoint: "me",
                method: "get",
            });
            dispatch(setUserData(response.data));
            dispatch(setAuth(true));
        } catch {
            dispatch(setUserData(false));
            dispatch(setAuth(false));
        }
    };
    useEffect(() => {
        if (auth === "load") {
            getUser();
        }
        if (!auth && auth !== "load" && location.pathname !== "/login") {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
    }, [location]);
    return (
        <>
            {auth !== "load" ? (
                auth ? (
                    <AuthRouter />
                ) : (
                    <NotAuthRouter />
                )
            ) : (
                <></>
            )}
            <ToastContainer />
        </>
    );
}

export default App;

import axios from "axios";

import { store } from "../redux";

const API_URL = `${process.env.REACT_APP_URL_API}`;

const api = axios.create({
    baseURL: API_URL,
});

var refreshPromise = false;

api.interceptors.request.use((config) => {
    if (localStorage.getItem("access")) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
            "access"
        )}`;
    }
    return config;
});

api.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        if (
            error?.response?.status === 401 &&
            error?.response.data?.code === "Пользователь неактивен"
        ) {
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
            store.dispatch({ type: "SET_AUTH", payload: false });
            store.dispatch({ type: "SET_USER_DATA", payload: false });
            throw error;
        }
        if (
            error?.response?.status === 401 &&
            error?.response.data?.detail !==
                "Не найдено активной учетной записи с указанными данными" &&
            error?.config &&
            !error?.config._isRetry
        ) {
            originalRequest._isRetry = true;
            if (!refreshPromise) {
                refreshPromise = axios.post(`${API_URL}/token-refresh/`, {
                    refresh: localStorage.getItem("refresh"),
                });
            }
            try {
                const response = await refreshPromise;
                localStorage?.setItem("access", response.data.access);
                localStorage?.setItem("refresh", response.data.refresh);
                return api.request(originalRequest);
            } catch (errorRefresh) {
                localStorage.removeItem("access");
                localStorage.removeItem("refresh");
                store.dispatch({ type: "SET_AUTH", payload: false });
                store.dispatch({ type: "SET_USER_DATA", payload: false });
                delete originalRequest.headers.Authorization;
                throw errorRefresh;
            } finally {
                refreshPromise = false;
            }
        } else {
            throw error;
        }
    }
);

const request = async (payload) => {
    let params = "";
    let data = "";
    if (payload.params) {
        params = `${payload.params}`;
    }
    if (payload.data) {
        data = payload.data;
    }
    switch (payload.method) {
        case "get":
            return await api.get(`/${payload.endPoint}/${params}`);
        case "post":
            return await api.post(`/${payload.endPoint}/${params}`, data);
        case "patch":
            return await api.patch(`/${payload.endPoint}/${params}`, data);
        case "delete":
            return await api.delete(`/${payload.endPoint}/${params}`);
        case "post-fd":
            return await api.post(`/${payload.endPoint}/${params}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        default:
            return await api.get(`/${payload.endPoint}/${params}`);
    }
};

export default request;

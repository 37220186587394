import { toast } from "react-toastify";

const showToast = (text, type) => {
    if (type === "success") {
        toast.success(text, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else if (type === "error") {
        toast.error(text, {
            theme: "colored",
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
        });
    }
};

export default showToast;

import styles from "./styles/checkBox.module.css";

const CheckBox = ({ checked, onChange }) => {
    return (
        <label class={styles.switch}>
            <input type="checkbox" checked={checked} onChange={onChange} />
            <span class={`${styles.slider} ${styles.round}`}></span>
        </label>
    );
};

export default CheckBox;

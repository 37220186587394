import styles from "./styles/modalMain.module.css";

import { useState, useEffect } from "react";

import formatDate from "../../helpers/formatDate";

import { GrClose } from "react-icons/gr";
import { IoIosVideocam } from "react-icons/io";

import { saveAs } from "file-saver";

import Button from "../UI/button/Button";
import Skeleton from "react-loading-skeleton";

const ModalMain = ({ active, setActive }) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (active === false) {
            setLoading(true);
        }
    }, [active]);
    return (
        <div
            className={styles.modal}
            style={{ display: active ? "flex" : "none" }}
            onClick={() => setActive(false)}
        >
            <div
                className={styles.modal__body}
                onClick={(e) => e.stopPropagation()}
            >
                <div>
                    <div className={styles.modal__image}>
                        {loading && (
                            <Skeleton
                                className={styles.modal__skeleton}
                                duration={0.4}
                            />
                        )}
                        {active.video_url && (
                            <div className="video_icon">
                                <IoIosVideocam size={48} />
                            </div>
                        )}
                        <img
                            src={`${process.env.REACT_APP_MEDIA_URL}${active.preview_url}`}
                            alt={`${active.name}`}
                            loading="lazy"
                            onLoad={() => setLoading(false)}
                        />
                    </div>
                    <div className={styles.modal__text}>
                        <p className={styles.modal__name}>{active.name}</p>
                        <p className={styles.modal__author}>{active.author}</p>
                        <p className={styles.modal__user}>{active.user}</p>
                        <p className={styles.modal__description}>
                            {active.description}
                        </p>
                        <p className={styles.modal__description}>
                            {active.general_description}
                        </p>
                        <p className={styles.modal__date}>
                            {formatDate(active.date_of_load)}
                        </p>
                        <Button
                            disabled={loading}
                            primary
                            style={{ marginLeft: "auto" }}
                            onClick={() => {
                                if (active.video_url) {
                                    saveAs(
                                        `${process.env.REACT_APP_MEDIA_URL}${active.video_url}`,
                                        `${active.name}`
                                    );
                                } else {
                                    saveAs(
                                        `${process.env.REACT_APP_MEDIA_URL}${active.image_url}`,
                                        `${active.name}`
                                    );
                                }
                            }}
                        >
                            Скачать
                        </Button>
                    </div>
                </div>
                <button
                    className={styles.modal__close}
                    onClick={() => setActive(false)}
                >
                    <GrClose size={18} />
                </button>
            </div>
        </div>
    );
};

export default ModalMain;

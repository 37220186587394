import styles from "./styles/cardMain.module.css";

import Skeleton from "react-loading-skeleton";

import noPreview from "../../assets/images/no-preview.jpg";

import { IoIosVideocam } from "react-icons/io";

import { useState } from "react";

const CardMain = ({ card, setActive }) => {
    const [loading, setLoading] = useState(true);
    return (
        <div className={styles.card} onClick={setActive}>
            <div className={styles.card__image}>
                <img
                    src={`${process.env.REACT_APP_MEDIA_URL}${card.preview_url}`}
                    alt={card.name}
                    loading="lazy"
                    onLoad={() => setLoading(false)}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = noPreview;
                    }}
                />
                {card.video_url && (
                    <div className="video_icon">
                        <IoIosVideocam size={38} />
                    </div>
                )}
                {loading && (
                    <Skeleton
                        className={styles.card__skeleton}
                        duration={0.4}
                    />
                )}
            </div>
            <div className={styles.card__text}>
                <p className={styles.card__name}>{card.name}</p>
                <p className={styles.card__author}>{card.user}</p>
                <p className={styles.card__description}>{card.description}</p>
            </div>
        </div>
    );
};

export default CardMain;

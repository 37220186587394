import { Routes, Route } from "react-router-dom";

import Header from "../components/UI/header/Header";

import Main from "../pages/main/Main";
import UpLoad from "../pages/upLoad/UpLoad";

const AuthRouter = () => {
    return (
        <>
            <Header />
            <Routes>
                <Route path={"/"} element={<Main />} />
                <Route path={"/upload"} element={<UpLoad />} />
            </Routes>
        </>
    );
};

export default AuthRouter;
